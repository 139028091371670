/** @jsx jsx */
import { Fragment } from "react"

import { Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import BlogArticleCard from "./blog-article-card"

const BlogRelated = ({
  heading,
  headingProps,
  backgroundColor,
  component: RenderComponent = BlogArticleCard,
  items,
  gridColumns = 4,
  gridRows = 2,
  itemsPerPage,
}) => {
  if (!Array.isArray(items) || !items.length) {
    return null
  }

  return (
    <Fragment>
      {heading && <Heading {...headingProps}>{heading}</Heading>}
      <div
        sx={{
          position: "relative",
          display: ["flex", "grid", "grid"],
          gridTemplateColumns: [
            "auto",
            "repeat(3, 1fr)",
            `repeat(${gridColumns}, 1fr)`,
          ],
          columnGap: [null, 5],
          rowGap: [5, 5],
          flexWrap: ["nowrap", "initial"],
          justifyContent: ["flex-start", "initial", "initial"],
          width: ["calc(100% + 24px)", "100%", "100%"],
          mx: [-3, 0],
          overflowX: ["auto", "initial", "initial"],
          scrollSnapType: "x mandatory",
          webkitOverflowScrolling: "touch",
        }}
      >
        {items.map((item, index) => (
          <div
            key={index}
            sx={{
              flexShrink: 0,
              width: ["calc(75% - 12px)", "100%"],
              mx: [3, 0],
              scrollSnapAlign: "start",
              scrollSnapStop: "always",
              scrollBehavior: "smooth",
              scrollMarginLeft: "12px",
            }}
          >
            <RenderComponent
              key={`Component-${(
                item?.title ||
                item?.heading ||
                item?.slug ||
                item?.id
              )
                ?.replace(/\s/gi, "-")
                ?.toLowerCase()}`}
              {...item}
            />
          </div>
        ))}
      </div>
    </Fragment>
  )
}

export default BlogRelated
