/** @jsx jsx */
import { GatsbyImage } from "gatsby-plugin-image"

import { Heading, Icon, TextLink } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"
import i18next, { Trans } from "@trueskin-web/translations"

const BlogArticleCard = ({
  id,
  className,
  noLines = 2,
  heading,
  description,
  image,
  background,
  articleCount,
  href,
  fullLink,
  buttonText = i18next.t("BlogArticleCard.buttonText"),
}) => {
  const Component = ({ children, className }) =>
    fullLink && href ? (
      <TextLink
        className={className}
        href={href}
        title={heading}
        isTextColor
        sx={{
          position: "relative",
          display: "block",
          background,
        }}
      >
        {children}
      </TextLink>
    ) : (
      <div className={className} sx={{ position: "relative", background }}>
        {children}
      </div>
    )

  const BottomLink = ({ children, className }) =>
    fullLink && href ? (
      <div className={className}>{children}</div>
    ) : (
      <TextLink className={className} href={href} isTextColor>
        {children}
      </TextLink>
    )

  return (
    <Component className={className}>
      <div
        sx={{
          position: "relative",
          width: "100%",
          "&:after": {
            content: `""`,
            display: ["none", "block"],
            pb: "100%",
          },
        }}
      >
        <div sx={{ position: ["relative", "absolute"], size: [null, "100%"] }}>
          <GatsbyImage
            image={image.imageFile.childImageSharp.gatsbyImageData}
            alt=""
            sx={{
              width: "100%",
              height: [null, "100%"],
              maxHeight: ["180px", "initial"],
            }}
          />
        </div>
      </div>

      <div
        sx={{
          position: "relative",
          flexGrow: 1,
          height: [null, "192px"],
          p: 4,
          pb: 8,
        }}
      >
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Heading
            as="h4"
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "2",
              flexGrow: 0,
              flexShrink: 0,
              maxHeight: 135,
              overflow: "hidden",
            }}
          >
            {heading}
          </Heading>
          <div
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: `${noLines}`,
              minHeight: 0,
              overflow: "hidden",
            }}
          >
            <p>{description}</p>
          </div>
        </div>

        <BottomLink
          sx={{
            position: "absolute",
            bottom: "-1px",
            transform: "translateY(-100%)",
            display: "block",
            width: "calc(100% - 24px)",
            borderBottom: "1px",
            cursor: "pointer",
          }}
        >
          <div
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              fontSize: [4, 4],
              lineHeight: "heading",
            }}
          >
            {buttonText}
            <Icon icon={arrowRightIcon} />
          </div>
        </BottomLink>
      </div>

      {/* Article counter */}
      {typeof articleCount !== "undefined" && articleCount !== null && (
        <div
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            px: 3,
            py: 2,
            color: "#fff",
            backgroundColor: "#000",
            fontSize: 0,
            fontFamily: "monospace",
          }}
        >
          <Trans
            i18nKey="BlogArticleCard.items"
            values={{ count: articleCount }}
          />
        </div>
      )}
    </Component>
  )
}

export default BlogArticleCard
