/** @jsx jsx */
import { GatsbyImage } from "gatsby-plugin-image"
import { Fragment } from "react"

import { Heading, TextLink } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { Slugs, Trans } from "@trueskin-web/translations"

import Blocks from "../block-library/components/blocks"

import Toc from "./toc"

const Author = ({ title, description, doctor, csx }) => (
  <div
    sx={{
      display: "flex",
      flexDirection: "column",
      ...csx,
    }}
  >
    {title && (
      <Heading as="h2" mb={5} sx={{ fontSize: 32 }}>
        {title}
      </Heading>
    )}
    <div
      sx={{
        display: "flex",
        alignItems: [null, "center"],
        flexDirection: ["column-reverse", "row"],
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          textAlign: ["center", "left"],
        }}
      >
        {doctor.name && (
          <Heading
            as="h3"
            sx={{
              mt: [5, 0],
            }}
          >
            {doctor.name}
          </Heading>
        )}

        {doctor.specialty && (
          <div
            sx={{
              mt: 2,
            }}
          >
            {doctor.specialty}
          </div>
        )}

        {doctor.hasPublicPage && doctor.slug && (
          <TextLink
            href={`${Slugs["our-doctors"].url}/${doctor.slug}`}
            isUnderlined
            isTextColor
            sx={{ display: "block", mt: 1 }}
          >
            <Trans i18nKey="Templates.BlogContent.profileLink" />
          </TextLink>
        )}

        {description && (
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
            sx={{
              textAlign: "left",
              mt: 3,
              mb: [6, 0],
              "*": {
                p: 0,
                m: 0,
              },
            }}
          ></div>
        )}
      </div>

      {doctor.image && (
        <GatsbyImage
          image={doctor.image.imageFile.childImageSharp.gatsbyImageData}
          alt=""
          sx={{
            width: 240,
            ml: ["auto", 5],
            mr: "auto",
            borderRadius: "50%",
          }}
        />
      )}
    </div>
  </div>
)

const BlogTableOfContent = ({ toc, blocks, author, hideTableOfContents }) => (
  <Fragment>
    <div
      sx={{
        display: "flex",
        flexDirection: ["column-reverse", "row"],
        borderBottom: "1px solid",
        mb: 6,
        pb: 6,
      }}
    >
      {!hideTableOfContents && (
        <Toc
          toc={toc}
          csx={{
            flex: 3,
          }}
        />
      )}
      {author && (
        <Author
          title={author?.title}
          description={author?.description}
          doctor={author?.doctor}
          csx={{
            flex: 5,
            ml: [null, toc && 5],
            pl: [null, toc && 5],
            borderLeft: [null, toc && "1px solid"],
          }}
        />
      )}
    </div>
    <Blocks
      blocks={blocks}
      sx={{
        "& h2": {
          fontSize: [4, 5],
        },
        "& h3": {
          fontSize: [4, 5],
        },
      }}
    />
  </Fragment>
)

export default BlogTableOfContent
